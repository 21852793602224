import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import styles from "./info-panel.module.scss"

function InfoPanel() {
  const data = useStaticQuery(graphql`
    query {
      allImageSharp(
        filter: { fluid: { originalName: { regex: "/info-panel-/" } } }
      ) {
        edges {
          node {
            fluid(maxWidth: 140) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  return (
    <div className={styles.infoPanel}>
      <Link to="/seguros-de-salud">
        <div className={styles.item}>
          <Img
            className={styles.gatsbyImageWrapper}
            fluid={
              data.allImageSharp.edges.find(
                item =>
                  item.node.fluid.originalName === "info-panel-insurance.png"
              ).node.fluid
            }
          />
          <div className={styles.text}>
            <h5>Seguros de salud</h5>
            <ul className="list-unstyled text-small">
              <li>
                Descubre nuestros planes con los que asegurar la salud de su
                mascota
              </li>
            </ul>
          </div>
        </div>
      </Link>
      <Link to="/donde-estamos">
        <div className={styles.item}>
          <Img
            className={styles.gatsbyImageWrapper}
            fluid={
              data.allImageSharp.edges.find(
                item =>
                  item.node.fluid.originalName === "info-panel-location.jpg"
              ).node.fluid
            }
          />
          <div className={styles.text}>
            <h5>Dónde estamos</h5>
            <ul className="list-unstyled text-small">
              <li>
                A 200m del Ayuntamiento de Meco y con un amplio parking en la
                entrada
              </li>
            </ul>
          </div>
        </div>
      </Link>
    </div>
  )
}

InfoPanel.propTypes = {}

InfoPanel.defaultProps = {}

export default InfoPanel
