import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TitleText from "../components/text/title-text/title-text"
import DescriptionText from "../components/text/description-text/description-text"
import InfoPanel from "../components/home/info-panel/info-panel"
import ReviewsCarousel from "../components/home/reviews-carousel/reviews-carousel"
import TopPanel from "../components/home/top-panel/top-panel"

import styles from "./index.module.scss"

function IndexPage() {
  const { edges: posts } = useStaticQuery(graphql`
    query BlogPostsSummaryQuery {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 2
        filter: { fileAbsolutePath: { regex: "/(blog-posts)/.*.md$/" } }
      ) {
        edges {
          node {
            excerpt(pruneLength: 200)
            id
            frontmatter {
              title
              path
            }
          }
        }
      }
    }
  `).allMarkdownRemark

  return (
    <Layout>
      <SEO title="Inicio" />
      <TopPanel />
      <div className="bg-color">
        <DescriptionText
          text={`La Clínica Veterinaria Meco se inauguró en noviembre de 1995 con la intención de ofrecer una atención integral para garantizar el bienestar y la salud de nuestras mascotas.\n\nConstituida por un equipo de profesionales con amplia experiencia y formación continuada, hemos invertido en ilusión y en los medios técnicos adecuados para llevar a cabo nuestro trabajo.`}
        />
      </div>
      <InfoPanel />
      <div className="bg-color">
        <ReviewsCarousel />
      </div>
      <TitleText text="Visita nuestro blog" />
      <div className={styles.blogPosts}>
        {posts
          .filter(post => post.node.frontmatter.title.length > 0)
          .map(({ node: post }) => {
            return (
              <div className={styles.blogPostPreview} key={post.id}>
                <Link to={post.frontmatter.path}>
                  <h4> {post.frontmatter.title}</h4>
                  <p>{post.excerpt}</p>
                  <br></br>
                  <p>Ir al blog</p>
                </Link>
              </div>
            )
          })}
      </div>
    </Layout>
  )
}

export default IndexPage
