import React from "react"
import Carousel from "react-bootstrap/Carousel"
import TitleText from "../../text/title-text/title-text"

import styles from "./reviews-carousel.module.scss"

function ReviewsCarousel() {
  const reviews = [
    "Muy profesionales, cercanos y cariñosos con las mascotas. Tienen bastantes recursos técnicos y diagnósticos.",
    "Me gusta mucho el trato que dan a nuestros peluditos y con mucho gusto ofrecen lo mejor para ellos.",
    "La mejor clínica que conozco. De hecho, vengo desde Madrid hasta Meco para que vean a mi perro.",
    "La atención y la profesionalidad que tiene todo el personal y el cariño con el que tratan a nuestros peludos.",
    "Muy profesional. Raúl además es muy amable y te explica todos los detalles del procedimiento a seguir con el perro con sus pros y contras. Muy satisfecho con el servicio recibido.",
    "Siempre atención perfecta, atentos, cariñosos, profesionales. Venimos desde hace más de 8 años y estamos los dos encantados.",
    "Maravillosos profesionales y trato cercano y siempre amable. Muy recomendable.",
  ]
  return (
    <>
      <TitleText text="Qué dicen nuestros clientes" />
      <div className={styles.carousel}>
        <Carousel
          interval={2500}
          className="full-width-md d-md-block"
          indicators={false}
        >
          {reviews.map((value, index) => (
            <Carousel.Item className={styles.carouselItem} key={index}>
              <div className={styles.carouselCard}>
                <p>
                  <strong>"</strong>
                  {value}
                  <strong>"</strong>
                </p>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>{" "}
      </div>
    </>
  )
}

ReviewsCarousel.propTypes = {}

ReviewsCarousel.defaultProps = {}

export default ReviewsCarousel
