import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import React from "react"
import { OverlayTrigger, Popover } from "react-bootstrap"
import styles from "./top-panel.module.scss"

function TopPanel() {
  const images = ["top-panel-dog.jpg", "top-panel-toshi.jpg"]
  var random = images[Math.floor(Math.random() * images.length)]

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">
        Llámanos o mándanos un WhatsApp al 91 886 05 15 <br />o escríbenos a
        cvmeco@cvmeco.es
      </Popover.Title>
    </Popover>
  )

  return (
    <StaticQuery
      query={graphql`
        query {
          allFile(filter: { relativePath: { regex: "/top-panel-.*.jpg$/" } }) {
            edges {
              node {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                    originalName
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const edge = data.allFile.edges.find(
          item => item.node.childImageSharp.fluid.originalName === random
        )
        return (
          <BackgroundImage
            style={{
              height: "400px",
              backgroundSize: "contain",
              backgroundPosition: "center bottom",
              backgroundRepeat: "no-repeat",
              backgroundColor: "white",
            }}
            fluid={edge.node.childImageSharp.fluid}
          >
            <div className={styles.container}>
              <h2 className={styles.text}>
                Sólo una mascota sana puede ser un compañero feliz
              </h2>
            </div>

            <div className={styles.appointment}>
              <OverlayTrigger trigger="click" placement="top" overlay={popover}>
                <button className={styles.button}>Pide cita</button>
              </OverlayTrigger>
            </div>
          </BackgroundImage>
        )
      }}
    />
  )
}

export default TopPanel
